import React, { useEffect, useState } from 'react';
import Layout from "../formateurs/global/Layout";
import { Button, List, TextInput } from "flowbite-react";
import { useCookies } from 'react-cookie'
import { useNavigate } from 'react-router-dom'

import Capture1 from '../../assets/guide/capt-guide-1.png'
import Capture2 from '../../assets/guide/capt-guide-2.png'
import Capture3 from '../../assets/guide/capt-guide-3.png'
import Capture4 from '../../assets/guide/capt-guide-4.png'
import Capture5 from '../../assets/guide/capt-guide-5.png'
import Capture6 from '../../assets/guide/capt-guide-6.png'
import Capture7 from '../../assets/guide/capt-guide-7.png'
import Capture8 from '../../assets/guide/capt-guide-8.png'
import Capture9 from '../../assets/guide/capt-guide-9.png'
import Capture10 from '../../assets/guide/capt-guide-10.png'
import Capture11 from '../../assets/guide/capt-guide-11.png'

function FormGuide() {
    const [cookies] = useCookies(['userInfo'])
    const [user, setUser] = useState([]);
    const navigate = useNavigate()

    useEffect(() => {
        
    }, []);


    return (
        <Layout>
            <div className="max-w-screen-lg">
                <h3 className="text-xl font-semibold">Planileo – mode d’emploi formateurs</h3>
                <div className="mt-4">
                    <h3 className="mb-2 text-xl font-bold dark:text-white">Gérer ses disponibilités</h3>
                    <p className="text-gray-500">Une option peut être posée par un OF uniquement sur les zones que vous avez indiquées comme « disponibilité ».</p>

                    <p className="text-gray-500 mt-4">Sur votre planning, cliquez sur le bouton vert en haut à droite pour ajouter une disponibilité.</p>
                    <img className='mb-4 mt-2' src={Capture1} alt="Capture guide 1" />
                    <p className="text-gray-500 mt-2">Une disponibilité peut être :</p>
                    <List>
                        <List.Item>Un jour</List.Item>
                        <List.Item>Une suite de jours</List.Item>
                    </List>
                    <p className="text-gray-500 mt-2">Choisir date de début et date de fin, puis cliquez sur « ajouter ».</p>
                    <img className='mb-4 mt-2' src={Capture2} alt="Capture guide 2" />

                    <p className="text-gray-500 mt-2">La disponibilité apparaît en vert :</p>
                    <img className='mb-6 mt-4' src={Capture3} alt="Capture guide 3" />

                    <p className="text-gray-500 mt-2">
                        Vous pouvez vous rendre indisponible sur une période en ajoutant sur cette zone une
                        « indisponibilité » si par exemple vous avez ensuite un autre engagement. 
                        Les indisponibilités sont affichées en gris sur le planning.
                    </p>
                    <img className='mb-4 mt-2' src={Capture4} alt="Capture guide 4" />
                    <img className='mb-4 mt-2' src={Capture5} alt="Capture guide 5" />
                    <p className="text-gray-500 mt-2">
                        Bien pratique si vous aviez saisi une « dispo » sur une semaine et que vous n’êtes
                        plus dispo, par exemple, qu’un seul jour de cette semaine !
                        Une option peut être posée par un OF uniquement sur les zones que vous avez
                        indiquées comme « disponibilité »
                    </p>
                    <h3 className="mt-8 mb-2 text-xl font-bold dark:text-white">Gérer ses disponibilités</h3>
                    <p className="text-gray-500 mt-2">Un organisme de formation vient de poser une option. Elle apparaît en orange sur votre planning</p>
                    <img className='mb-4 mt-2' src={Capture6} alt="Capture guide 6" />
                    <p className="text-gray-500 mt-2">Et vous avez reçu un mail :</p>
                    <img className='mb-4 mt-2' src={Capture7} alt="Capture guide 7" />
                    <p className="text-gray-500 mt-2">Cliquez sur le lien « Planileo » pour répondre au mail et valider la mission.</p>
                    <img className='mb-4 mt-2' src={Capture8} alt="Capture guide 8" />
                    <p className="text-gray-500 mt-2">L’option sur votre planning est passée en jaune, ce qui vous indique, et indique à
                    l’OF, que vous avez validé cette option. Vous pouvez aussi la refuser (si vous aviez oublié de mettre votre planning à jour !)</p>
                    <img className='mb-4 mt-2' src={Capture9} alt="Capture guide 9" />
                    <p className="text-gray-500 mt-2">L’OF reçoit un mail qui indique votre choix</p>
                    <img className='mb-4 mt-2' src={Capture10} alt="Capture guide 10" />
                    <p className="text-gray-500 mt-2">L’option sur votre planning est passée en vert, ce qui vous indique que l’OF a
                    validé définitivement cette date.</p>
                    <p className="text-gray-500 mt-2">Et vous avez reçu un mail de confirmation</p>
                    <img className='mb-4 mt-2' src={Capture11} alt="Capture guide 11" />

                    <h3 className="mb-2 text-xl font-bold dark:text-white">Annulation d'une formation</h3>
                    <p className="text-gray-500 mt-2">Dans ce cas, vous recevez un mail automatique vous informant de l’annulation
                    de cette formation. S’il s’agit d’un changement de dates, l’OF posera une autre
                    option et le process recommence !</p>

                    <h3 className="mt-8 mb-2 text-xl font-bold dark:text-white">Autres infos</h3>
                    <h4 className="mb-2 text-l font-bold dark:text-white">Détail de l’option :</h4>
                    <p className="text-gray-500">Dans ce champ apparaissent les infos complémentaires que l’OF vous donne
                    (lieu, type de formation, etc). C’est un champ de saisie libre pour l’OF.</p>
                    <h4 className="mb-2 text-l font-bold dark:text-white">Votre adresse mail</h4>
                    <p className="text-gray-500">Au risque de me répéter, indiquez l’adresse mail que vous utilisez pour
                    communiquer avec nous est primordial, car c’est celle-ci que nous allons
                    rechercher pour relier votre compte « formateur » à un « organisme de
                    formation »</p>
                    <h4 className="mb-2 text-l font-bold dark:text-white">Diffusion de votre planning à d’autres OF</h4>
                    <p className="text-gray-500">A terme, le service planileo sera ouvert à d’autres organismes de formation.
                    Divers abonnements seront proposés aux OF.</p>
                    <h4 className="mb-2 text-l font-bold dark:text-white">Visibilité de votre planning</h4>
                    <p className="text-gray-500">Celui-ci n’est visible que par les OF qui vous ont fait une demande … et que vous
                    avez acceptés.</p>
                    <h4 className="mb-2 text-l font-bold dark:text-white">Évolution de planileo</h4>
                    <p className="text-gray-500">Le logiciel est en test dans un premier temps, il n’est ouvert qu’aux agences de
                    Nouvelle Route. Au fur et à mesure des semaines, des améliorations mineures
                    vont subvenir.
                    Des versions ultérieures vont intégrer d’autres services : liaison automatique avec
                    votre agenda, envoi des plannings du mois, etc</p>
                </div>
            </div>
        </Layout>
    );
}
export default FormGuide;