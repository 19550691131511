import React, { useEffect, useState } from 'react';
import Layout from "./global/Layout";
import { useNavigate } from 'react-router-dom'
import { Button, Table, Modal, Label, TextInput, Alert, Badge } from "flowbite-react";
import { HiInformationCircle, HiOutlineTrash, HiOutlineExclamationCircle } from "react-icons/hi";
import FilAriane from "../../components/FilAriane";
import { useCookies } from 'react-cookie'


const MesFormateurs = () => {
    let [formateurs, setFormateurs] = useState(false);
    let [selectedForm, setSelectedForm] = useState(false);
    const [user, setUser] = useState([]);
    let [email, setEmail] = useState("");
    let [isError, setIsError] = useState(false);
    let [invitSent, setInvitSent] = useState(false);
    let [formResult, setFormResult] = useState(false);
    const [cookies] = useCookies(['userInfo'])
    const [openModal, setOpenModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const navigate = useNavigate();

    const getFormateurs = async () => {
        const response = await fetch('https://api.planileo.fr/orgs/getFormateurs', {
            method: 'GET',
            credentials: 'include'
        });
        if( response.status === 401 ) {
            alert('Votre session a expiré. Veuillez vous reconnecter.')
            navigate('/login', {replace: true})
        } else if( response.status !== 200 ) {
            console.log('error');
        } else {
            const result = await response.json();
            setFormateurs(result.data);
        }
    };

    const getUserInfo = async () => {
        const response = await fetch('https://api.planileo.fr/users/getUserInfo/' + cookies.userInfo.id, {
            method: 'GET',
            credentials: 'include',
        });
        if (response.status === 401) {
            alert('Votre session a expiré. Veuillez vous reconnecter.')
            navigate('/login', { replace: true })
        } else if (response.status !== 200) {
            alert('Une erreur est survenue')
        } else {
            const res = await response.json()
            setUser(res)
        }
    };

    useEffect(() => {
        getFormateurs();
        getUserInfo();
    }, []);

    const searchForm = async() => {
        const response = await fetch('https://api.planileo.fr/orgs/searchFormateur/' + email, {
            method: 'GET',
            credentials: 'include'
        });
        if (response.status === 401) {
            alert('Votre session a expiré. Veuillez vous reconnecter.')
            navigate('/login', { replace: true })
        } else if (response.status !== 200) {
            alert('Une erreur est survenue')
        } else {
            const result = await response.json();
            if ( result.formateur ) {
                setIsError(false);
                setInvitSent(false)
                setFormResult(result.formateur)
                setEmail("")
            } else {
                setInvitSent(false)
                setIsError(true)
            }
        }
    }

    const sendInvitation = async(formateurEmail) => {
        setIsError(false)
        setInvitSent(true)
        const response = await fetch('https://api.planileo.fr/notifications/sendInvitation', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                entreprise: user.entreprise,
                emailOf: user.email,
                to: formateurEmail
            }),
        });
        if (response.status === 401) {
            alert('Votre session a expiré. Veuillez vous reconnecter.')
            navigate('/login', { replace: true })
        } else if (response.status !== 200) {
            alert('Une erreur est survenue')
        } else {
            const result = await response.json();
            if( result.success === true ) {
                setInvitSent(true)
            } else {
                alert(result.message);
            }
        }
    }

    const addRelation = async (formateurId) => {
        const response = await fetch('https://api.planileo.fr/relations/addOrgRelation/' + formateurId, {
            method: 'POST',
            credentials: 'include'
        });
        if (response.status === 401) {
            alert('Votre session a expiré. Veuillez vous reconnecter.')
            navigate('/login', { replace: true })
        } else if (response.status !== 200) {
            alert('Une erreur est survenue')
        } else {
            const result = await response.json();
            if( result.success === true ) {
                window.location.reload()
            } else {
                alert(result.message);
            }
        }
    };

    const deleteRelation = async () => {
        const response = await fetch('https://api.planileo.fr/relations/deleteOrgRelation/' + selectedForm, {
            method: 'POST',
            credentials: 'include'
        });
        if (response.status === 401) {
            alert('Votre session a expiré. Veuillez vous reconnecter.')
            navigate('/login', { replace: true })
        } else if (response.status !== 200) {
            alert('Une erreur est survenue')
        } else {
            const result = await response.json();
            if( result.success === true ) {
                window.location.reload()
            } else {
                alert('Une erreur est survenue')
            }
        }
    }

    return (
        <Layout>
            <FilAriane firstItem={{label:"Formateurs", url:"/form/mes-formateurs"}} />
            <div className="mb-4 block md:flex md:items-center md:justify-between">
                <div className="mb-4 md:mb-0">
                    <h3 className="text-l font-semibold md:text-xl">Liste des formateurs ajoutés</h3>
                    <p className="text-sm text-gray-600 md:text-lg">Vous pouvez voir les plannings de ces formateurs et poser des options dessus.</p>
                </div>
                <Button onClick={() => {setOpenModal(true)}}>Ajouter un formateur</Button>
            </div>
            <div className="overflow-x-auto mt-6">
                <Table>
                    <Table.Head>
                        <Table.HeadCell className="px-2 py-2 font-small md:font-medium md:px-6 md:py-4">Nom</Table.HeadCell>
                        <Table.HeadCell className="px-2 py-2 font-small md:font-medium md:px-6 md:py-4">Prénom</Table.HeadCell>
                        <Table.HeadCell className="px-2 py-2 hidden font-small md:font-medium md:px-6 md:py-4 sm:block">Email</Table.HeadCell>
                        <Table.HeadCell className="px-2 py-2 font-small md:font-medium md:px-6 md:py-4">
                            <span className="sr-only">Edit</span>
                        </Table.HeadCell>
                    </Table.Head>
                    <Table.Body className="divide-y">
                        {formateurs && formateurs.length > 0 && formateurs.map((formateur, index) => (
                            <Table.Row key={index} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                <Table.Cell className="px-2 py-2 text-xs md:font-medium md:px-6 md:py-4 sm:text-sm">{formateur.nom}</Table.Cell>
                                <Table.Cell className="px-2 py-2 hidden text-xs md:font-medium md:px-6 md:py-4 sm:text-sm sm:table-cell">{formateur.prenom}</Table.Cell>
                                <Table.Cell className="px-2 py-2 text-xs font-medium text-gray-900 md:px-6 md:py-4 sm:text-sm">
                                    {formateur.email}
                                </Table.Cell>
                                <Table.Cell className="px-2 py-2 md:px-6 md:py-4">
                                    {formateur.status === 'pending' ?(
                                        <div className="flex items-center">
                                            <Badge color="warning">Demande envoyée au formateur</Badge>
                                        </div>
                                    ) : (
                                        <div className="flex items-center">
                                            <Button size="sm" onClick={() => navigate('/org/planning-formateur/' + formateur.id, {replace: true})} className="mr-2 text-xs sm:textsmall">
                                                <span className="hidden md:block">Voir le&nbsp;</span>planning
                                            </Button>
                                            <HiOutlineTrash className="cursor-pointer" onClick={() => {setSelectedForm(formateur.id); setOpenDeleteModal(true) }} size={24}/>
                                        </div>
                                    )}
                                </Table.Cell>
                            </Table.Row>
                        ))} 
                    </Table.Body>
                </Table>
                {formateurs.length === 0 && 
                    <p className="font-small text-gray-600 ml-4 mt-2">Vous n'avez ajouté aucun formateur pour l'instant</p>
                }
            </div>
            <Modal show={openModal} size="md" onClose={() => setOpenModal(false)} popup>
                <Modal.Header />
                <Modal.Body>
                <div>                    
                    {!formResult ? (
                        <div>
                            <div className="mb-4">
                                <h3 className="text-xl font-medium text-gray-900 dark:text-white">Recherchez votre formateur</h3>
                                <p className="text-sm text-gray-600">Entrez l'adresse email du formateur pour le rechercher dans Planileo</p>
                            </div>
                            <Alert className={`${!isError ? "hidden" : "mb-2"}`} color="failure" icon={HiInformationCircle}>
                                <span className="font-medium">Formateur non trouvé</span>
                                <p className="underline cursor-pointer" onClick={() => sendInvitation(email)}>Envoyer une invitation à utiliser Planileo</p>
                            </Alert>
                            <Alert color="success" className={`${!invitSent ? "hidden" : "mb-2"}`}>
                                <span className="font-medium">Invitation envoyée</span> Le formateur a reçu votre demande pour s'inscrire à Planileo.
                            </Alert>
                            <div className="block">
                                <Label htmlFor="email" value="Adresse email du formateur" />
                            </div>
                            <TextInput
                                className="mt-2"
                                id="email"
                                placeholder="nom@exemple.com"
                                value={email}
                                onChange={(event) => setEmail(event.target.value)}
                                required
                            />
                            <div className="w-full mt-4">
                                <Button onClick={() => searchForm()}>Rechercher un formateur</Button>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <h4 className="text-xl font-medium text-gray-900">Résultat de la recherche</h4>
                            <p className="text-sm text-gray-600 mb-4">Un formateur avec cette adresse email est sur Planileo</p>    
                            <div className="flex text-sm mb-6 bg-gray-100 text-gray-800 px-4 py-3 rounded-md">
                                <p>{formResult.prenom} {formResult.nom} ({formResult.email})</p>
                            </div>
                            <Button onClick={() => addRelation(formResult.id)}>Ajouter le formateur</Button>
                            <p onClick={() => setFormResult(false)} className="text-sm text-gray-600 text-right cursor-pointer">Faire une autre recherche</p>  
                        </div>
                    )}

                </div>
                </Modal.Body>
            </Modal>
            <Modal show={openDeleteModal} size="md" onClose={() => setOpenDeleteModal(false)} popup>
                <Modal.Header />
                <Modal.Body>
                    <div className="text-center">
                        <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                            Etes-vous sur de vouloir supprimer ce formateur de vos relations ? Vous ne pourrez plus voir son planning ni poser d'options dessus.
                        </h3>
                        <div className="flex justify-center gap-4">
                            <Button color="failure" onClick={() => {setOpenDeleteModal(false); deleteRelation()}}>
                                {"Confirmer"}
                            </Button>
                            <Button color="gray" onClick={() => setOpenDeleteModal(false)}>
                                Annuler
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </Layout>
    );
}
export default MesFormateurs;