import React, { useEffect, useState } from 'react';
import Layout from "./global/Layout";


function OrgDashboard() {
    return (
        <Layout>
            <div className="pt-6">
                <h3 className="text-xl font-semibold">Planileo</h3>
                <p>Bienvenue dans votre espace organisme de formation</p>
            </div>
        </Layout>
    );
}
export default OrgDashboard;