import LoginForm from "./LoginForm";
import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom'
import { Alert } from "flowbite-react";
import { HiInformationCircle } from "react-icons/hi";
import { useCookies } from 'react-cookie'
import { useNavigate } from 'react-router-dom'

function Login() {
    const [Registered, setRegistered] = useState(false);
    const { state } = useLocation();
    const navigate = useNavigate();
    const [cookies] = useCookies(['userInfo']);

    useEffect(() => {
        if (cookies.userInfo) {
            navigate('/', { replace: true })
        }
        if (state && state.justRegistered === true) {
            setRegistered(true)
        }
    }, []);

    return (
        <div className="flex flex-col items-center justify-center w-screen h-screen">
            {Registered &&
                <Alert className="w-full max-w-lg mb-4" color="success" icon={HiInformationCircle}>
                    <span className="font-medium">Votre compte a été créé ! Vous pouvez vous connecter ci-dessous</span>
                </Alert>
            }
            <LoginForm />
        </div>
    );
}
export default Login;